<template>
  <div
    v-if="!isAppoinmentMode"
    class="status-guide order-3 order-lg-1 mt-3 mt-lg-1"
  >
    <div class="me-3">Filters:</div>
    <div class="status-list">
      <CCheckBox
        :disabled="activePassState === 'ended'"
        label="Waiting Approval"
        :checked="filterData.waiting_approval"
        name="waiting_approval"
        :inline="true"
        :custom="true"
        class="custom-checkbox edited"
        @change="updateFilters"
      />
      <CCheckBox
        v-if="passesTimeSettings && passesTimeSettings.min_time"
        :label="
          $helpers.formatDate(passesTimeSettings.min_time, 'hh:mm:ss', 'mm') +
          '+Min'
        "
        :checked="filterData.yellow_time"
        name="yellow_time"
        :inline="true"
        :custom="true"
        class="custom-checkbox min-10"
        @change="updateFilters"
      />
      <CCheckBox
        :disabled="activePassState === 'active'"
        label="System Ended"
        :checked="filterData.system_ended"
        name="system_ended"
        :inline="true"
        :custom="true"
        class="custom-checkbox system-ended"
        @change="updateFilters"
      />
      <CCheckBox
        :disabled="activePassState === 'active'"
        label="Extended Passes"
        :checked="filterData.extended_time"
        name="extended_time"
        :inline="true"
        :custom="true"
        class="custom-checkbox extended-passes"
        @change="updateFilters"
      />
      <CCheckBox
        :disabled="activePassState === 'active'"
        label="Edited"
        :checked="filterData.edited"
        name="edited"
        :inline="true"
        :custom="true"
        class="custom-checkbox gray"
        @change="updateFilters"
      />
      <CCheckBox
        label="Student Number"
        :checked="filterData.student_sis_id"
        name="student_sis_id"
        class="custom-checkbox"
        @change="updateFilters"
        :inline="true"
        :custom="true"
      />
      <CCheckBox
        label="Limit Exceeded"
        :checked="filterData.limit_exceeded"
        name="limit_exceeded"
        class="custom-checkbox"
        @change="updateFilters"
        :inline="true"
        :custom="true"
      />
      <template v-if="isTardyAllowedForCurrentUser">
        <CCheckBox
          label="Late To Class"
          :checked="filterData.late_to_class"
          name="late_to_class"
          class="custom-checkbox"
          @change="updateFilters"
          :inline="true"
          :custom="true"
        />
        <CCheckBox
          label="Late To School"
          :checked="filterData.late_to_school"
          name="late_to_school"
          class="custom-checkbox"
          @change="updateFilters"
          :inline="true"
          :custom="true"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { computed } from "vue"
import { useStore } from "vuex"

export default {
  name: "DashboardTimeFiltersOld",
  setup() {
    const store = useStore()

    const isActiveAppoinmentModule = computed(
      () => store.getters["schools/isActiveAppoinmentModule"]
    )

    const passesTimeSettings = computed(
      () => store.getters["passes/passesTimeSettings"]
    )

    const activeSchoolHasAppoitmentPasses = computed(
      () => store.getters["schools/activeSchoolHasAppoitmentPasses"]
    )

    const filterData = computed(
      () => store.getters["dashboardTable/filterData"]
    )

    const activePassState = computed(
      () => store.getters["dashboardTable/getStatus"]
    )

    const activePassType = computed(
      () => store.getters["dashboardTable/getType"]
    )

    const userRole = computed(() => store.getters["authentication/getUserRole"])

    const tardyOptions = computed(
      () => store.getters["schools/tardyManagementOptions"]
    )

    const user = computed(() => store.getters["authentication/getUser"])

    const isAppoinmentMode = computed(() => {
      return activePassType.value.includes("appointment")
    })

    const isTardyAllowedForCurrentUser = computed(() => {
      if (!tardyOptions.value || !tardyOptions.value?.usage?.["within-ehp"]) {
        return false
      }
      if (userRole.value === "admin") {
        return true
      }
      const isTeacherOrStaff = ["staff", "teacher"].includes(userRole.value)
      const allowTeacherStaff =
        tardyOptions.value?.["allow-teacher-staff"] || false
      const allowSelectedUsersOnly =
        tardyOptions.value?.["allow-selected-users-only"] || false
      if (isTeacherOrStaff && allowTeacherStaff) {
        return true
      }
      if (user.value?.allow_tardy && allowSelectedUsersOnly) {
        return true
      }
      return false
    })

    const updateFilters = (event) => {
      store.commit("dashboardTable/UPDATE_FILTERS_DATA", {
        filter: event.target.name,
        value: event.target.checked
      })
    }

    return {
      isActiveAppoinmentModule,
      passesTimeSettings,
      activeSchoolHasAppoitmentPasses,
      filterData,
      activePassState,
      activePassType,
      isAppoinmentMode,
      isTardyAllowedForCurrentUser,
      updateFilters
    }
  }
}
</script>
